// debug.js
'use strict';
import { pinoDebug } from './pino.js';

globalThis.enableDebug = debugString => {
  const debugs = debugString.split(',');
  debugs.forEach(debug => pinoDebug.enable(debug));
};

/**
 * Disable debug
 */
export const disableDebug = () => {
  return pinoDebug.disable();
};

/**
 * Enable debug
 * @param {string} {namespace} - debug namespace
 */
export const enableDebug = ({ namespace }) => {
  return pinoDebug.enable(namespace);
};

export const init = pinoDebug('init');
export const redux = pinoDebug('redux');
export const persist = pinoDebug('persist');
export const player = pinoDebug('player');
export const payment = pinoDebug('payment');
export const pusher = pinoDebug('pusher');
export const pushy = pinoDebug('pushy');
export const mixpanel = pinoDebug('mixpanel');
export const pubsub = pinoDebug('pubsub');
export const gtm = pinoDebug('gtm');
export const component = pinoDebug('component');
export const actionError = pinoDebug('action:error');
export const actionLog = pinoDebug('action:log');
export const selectorError = pinoDebug('selector:error');
export const serviceWorkerLog = pinoDebug('serviceWorker:log');
export const serviceWorkerError = pinoDebug('serviceWorker:error');
export const workerMessenger = pinoDebug('workerMessenger');
export const resource = pinoDebug('resource');
export const resourceLog = pinoDebug('resource:log');
export const legacySSR = pinoDebug('legacy-ssr');
export const redis = pinoDebug('redis');
export const fetchCache = pinoDebug('fetch-cache');
export const upload = pinoDebug('upload');
export const game = pinoDebug('game');
export const shorts = pinoDebug('shorts');
export const liveStreamCard = pinoDebug('liveStreamCard');
export const pushNotification = pinoDebug('pushNotification');
export const turnstile = pinoDebug('turnstile');
export const customFetch = pinoDebug('customFetch');
export const queue = pinoDebug('queue');
export const buildHtml = pinoDebug('build-html');
export const sitemapHandler = pinoDebug('sitemap-handler');
export const nextApp = pinoDebug('next-app');
export const campaigns = pinoDebug('campaigns');

export default {
  init,
  actionError,
  actionLog,
  selectorError,
  player,
  payment,
  pusher,
  gtm,
  mixpanel,
  serviceWorkerLog,
  serviceWorkerError,
  workerMessenger,
  resource,
  resourceLog,
  legacySSR,
  redis,
  fetchCache,
  upload,
  disableDebug,
  enableDebug,
  campaigns,
};
